import React, {Component, Suspense} from "react";
import {connect} from "react-redux";
import {setUser} from "./store/actions/auth-actions";
import {BrowserRouter, Switch, Redirect} from "react-router-dom";
import {routes} from "./router";
import {f_getUserById} from "./services";
import Moment from "moment";
import "moment/locale/tr";

import {getUser} from "./helpers/storageManager";

Moment.locale("tr");


const MainLayout = React.lazy(() => import("./layouts/MainLayouts/MainLayout"));
const AuthLayout = React.lazy(() => import("./layouts/AuthLayouts/AuthLayout"));
const FreeLayout = React.lazy(() => import("./layouts/FreeLayouts/FreeLayout"));

const withLayout = WrappedComponent => {
  const HOC = class extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};


class App extends Component {
  constructor(props) {
    super(props);
    const user = getUser();
    if (user) {
      props.onSetUser(user);
    }
  }

  getLayout = (layout) => {
    switch (layout) {
      case "main":
        return MainLayout
      case "auth":
        return AuthLayout
      default:
        return FreeLayout
    }
  };

  componentDidMount() {
    if(!!this.props.user && this.props.user.project) {
      document.title = this.props.user.project.name;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('props', this.props);
    if(!!this.props.user && !!this.props.user.projectName) {
      document.title = this.props.user.projectName;
    }
  }


  render() {
    return (
      // rendering the router with layout
      <BrowserRouter>
        <React.Fragment>
          <Switch>
            {routes.map((route, index) => {
              return (
                <route.route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  roles={route.roles}
                  component={withLayout(props => {
                    const Layout = this.getLayout(route.layout);
                    return (
                      <Suspense fallback={<div>Loading</div>}>
                        <Layout {...props}>
                          <route.component {...props} />
                        </Layout>
                      </Suspense>
                    );
                  })}
                />
              );
            })}
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = {
  onSetUser: setUser
}


export default connect((state) => {console.log(state);return ({user: state.authStore.user})}, mapDispatchToProps)(App);
