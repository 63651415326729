// export const BASE_URL = "http://ets.loopbs.com/api/";
export const BASE_URL = "https://newetsapi.tesisyonetim.pro/api/";
export const BASE_URL_APP = "https://newetsapi.tesisyonetim.pro";

export const LOGIN = BASE_URL + "Auth/login/";

export const GET_USER = BASE_URL + "Users/GetUserById/";

export const PROJECT_SELECTS = BASE_URL + "Users/getUserProjectByUserID/";

export const CAMPUS_LIST = BASE_URL + "Campus/GetCampusListByProjectID/";

export const BUILD_LIST = BASE_URL + "Build/GetBuildListByCampusID/";

export const FLOOR_LIST = BASE_URL + "Floor/GetFloorListByBuildID/";

export const ROOM_LIST = BASE_URL + "Room/GetRoomListByFloorID/";

export const ALL_PROJECT = BASE_URL + "";

export const GET_PROJECT = BASE_URL + "Project/GetProjectById/";

export const CREATE_CAMPUS = BASE_URL + "Campus/AddCampus/";

export const GET_CAMPUS = BASE_URL + "Campus/GetCampusById/";

export const GET_BUILD = BASE_URL + "Build/GetBuildById/";

export const CREATE_FLOOR = BASE_URL + "Floor/AddFloor/";

export const GET_FLOOR = BASE_URL + "Floor/GetFloorById/";

export const CREATE_ROOM = BASE_URL + "Room/AddRoom/";

export const CREATE_BUILD = BASE_URL + "Build/AddBuild/";

export const GET_GROUP = BASE_URL + "Group/GetGroupListByProjectID/";

export const GET_BRAND = BASE_URL + "Group/GetBrandListByGroupID/";

export const GET_MODEL = BASE_URL + "Group/GetModelListByBrandID/";

export const ADD_GROUP = BASE_URL + "Group/AddGroup/";

export const ADD_BRAND = BASE_URL + "Group/AddBrand/";

export const ADD_MODEL = BASE_URL + "Group/AddModel/";

export const CAPACITY_LIST =
  BASE_URL + "InventoryAgreement/GetInventoryCapacityList/";

export const ADD_INVENTORY = BASE_URL + "Inventory/AddInventory/";
export const UPDATE_INVENTORY = BASE_URL + "Inventory/UpdateInventory/";

export const INVENTORY_LIST =
  BASE_URL + "Inventory/GetInventoryListByProjectID/";

export const ADD_MAINTENANCE =
  BASE_URL + "InventoryMaintenanceType/AddInventoryMaintenanceType/";

export const GET_INVENTORY = BASE_URL + "Inventory/GetInventoryById/";

export const MQ_LIST = BASE_URL + "MQuestion/GetMQFormTypeListByProjectID/";

export const OHS_LIST = BASE_URL + "OHS/GetOHSFormTypeListByProjectID/";

export const IA_LIST_OLD =
  BASE_URL + "InventoryAgreement/GetInventoryAgreementFormListByProjectID/";

export const IA_LIST =
  BASE_URL + "InventoryAgreement/GetInventoryAgreeFormList/";

export const MAINTENANCETYPES_LIST =
  BASE_URL + "MaintenanceCommon/GetMaintenanceTypeListByProjectId/";

export const MAINTENANCEPERIOD_LIST =
  BASE_URL + "MaintenanceCommon/GetMaintenancePeriodListByProjectId/";

export const INVENTORYMAINTENANCE_LIST =
  BASE_URL + "InventoryMaintenanceType/GetInventoryMaintenanteTypeList/";

export const MQUESTION_LIST =
  BASE_URL + "/MQuestion/GetMQuestionListByCompanyID/";

export const ADD_MQUESTION = BASE_URL + "/MQuestion/AddMQuestion/";

export const MQFORM_LIST =
  BASE_URL + "/MQuestion/GetMQFormTypeListByProjectID/";

export const ADD_MQFORM = BASE_URL + "/MQuestion/AddMQFormType/";

export const ADD_QUEST_FORM = BASE_URL + "/MQuestion/AddMQFormTypeQuestion/";

export const DELETE_QUEST_FORM =
  BASE_URL + "/MQuestion/DeleteMQFormTypeQuestion/";

export const QUEST_LIST_BY_FORM =
  BASE_URL + "/MQuestion/GetMQuestionListByFormId/";

export const ADD_MQUEST_MULTI_FORM =
  BASE_URL + "/MQuestion/AddMultiMQFormTypeQuestion/";

export const ADD_OHSQUEST = BASE_URL + "/OHS/AddOHSQuestion/";

export const GET_OHSQUEST_LIST =
  BASE_URL + "/OHS/GetOHSQuestionListByCompanyID/";

export const ADD_OHSFORM = BASE_URL + "/OHS/AddOHSFormType/";

export const OHS_QUEST_FORM = BASE_URL + "/OHS/GetOHSQuestionListByFormId/";

export const ADD_OHSQUEST_MULTI_FORM =
  BASE_URL + "/OHS/AddMultiOHSFormTypeQuestion/";

export const DELETE_OHSQUEST_FORM =
  BASE_URL + "/OHS/DeleteOHSFormTypeQuestion/";

export const NOTE_LIST = BASE_URL + "/Note/GetNoteListByProjectID/";

export const ADD_NOTE = BASE_URL + "/Note/AddNote/";

export const PERIOD =
  BASE_URL + "/MaintenanceCommon/GetMaintenancePeriodListByProjectId/";

export const ADD_PERIOD = BASE_URL + "/MaintenanceCommon/AddMaintenancePeriod/";

export const MTYPE =
  BASE_URL + "/MaintenanceCommon/GetMaintenanceTypeListByProjectId/";

export const ADD_MTYPE = BASE_URL + "/MaintenanceCommon/AddMaintenanceType/";

export const USER_ROLE = BASE_URL + "//Users/GetUserRoleList/";

export const USER_LIST = BASE_URL + "/Users/GetUserListByProjectID/";

export const ADD_USER = BASE_URL + "/Users/AddUser/";

export const ADD_CAPACITY =
  BASE_URL + "/InventoryAgreement/AddInventoryCapacity/";

export const USER_PROJECTS = BASE_URL + "/Users/getUserProjectByUserID/";

export const ADD_PROJECT_USER = BASE_URL + "/Users/AddUserProject/";

export const PROJECT_LIST = BASE_URL + "/Project/GetProjectListByCompanyID/";

export const MAINTENANCETYPES_LIST_ENUM =
  BASE_URL + "/MaintenanceCommon/GetMaintenanceTypeEnums/";

export const MAINTENANCEPERIOD_LIST_ENUM =
  BASE_URL + "/MaintenanceCommon/GetPeriodEnums/";

export const ADD_IA_OLD =
  BASE_URL + "/InventoryAgreement/AddInventoryAgreementForm/";

export const ADD_IA =
  BASE_URL + "/InventoryAgreement/AddInventoryAgreementForm/";

export const EDIT_USER = BASE_URL + "/Users/UpdateUser/";

export const TIMELINE = BASE_URL + "/MaintenanceCommon/MaintenanceTimeLine/";

export const MAINTENANCE_NOTE_LIST =
  BASE_URL + "/InventoryMaintenanceDetail/GetMaintenanceNotes/";

export const HOMEPAGE_STATISTICS =
  BASE_URL + "/MaintenanceReport/GetHomePageStatistics/";

export const UPCOMING_MAINTENANCE =
  BASE_URL + "/MaintenanceReport/GetComingMaintenance/";

export const COMPLETED_MAINTENANCE =
  BASE_URL + "/MaintenanceReport/GetCompletedMaintenance/";

export const UNDONE_MAINTENANCE =
  BASE_URL + "/MaintenanceReport/GetNotCompletedMaintenance/";

export const ABANDONED_LEGAL_MAINTENANCE =
  BASE_URL + "/MaintenanceReport/GetAbandonedLegalMaintenance/";

export const GET_INVENTORY_MAINTENANCE_TYPE_LIST =
  BASE_URL + "/Inventory/GetInventoryMaintenanceTypeList/";

export const GET_INVENTORY_BY_ID = BASE_URL + "/Inventory/GetInventoryById";

export const CHANGE_PASSWORD = BASE_URL + "/Users/UpdateUserPasswordChange/";
