var initialState = {
  isLogged: false,
  user: null
};

export default function Auth(state = initialState, action) {
  switch (action.type) {
    case "SET_USER":
      return { ...state, isLogged: true, user: action.payload };
    case "CLEAR_USER":
      return {...state, isLogged: false, user: null}
    default:
      return { ...state };
  }
}
