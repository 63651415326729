import * as constants from "./constants";
import { apiAuth, apiClient } from "./apiClient";

const formDataOptions = { "Content-Type": "multipart/form-data" };

export const initialValue = {
  data: null,
  status: null,
  error: null,
};

export const pendingValue = {
  data: null,
  status: "pending",
  error: null,
};

export const dataValue = (data) => ({
  data,
  status: "fulfilled",
  error: null,
});

export const errorValue = (error) => ({
  data: null,
  status: "rejected",
  error,
});

export const vals = {
  initial: initialValue,
  pending: pendingValue,
  suc: dataValue,
  err: errorValue,
};

export function f_login({ username, password }) {
  return apiAuth.get(
    constants.LOGIN + `?username=${username}&pass=${password}`
  );
}

export function f_getUserById(userId) {
  return apiClient().get(constants.GET_USER + `?userId=${userId}`);
}

export function f_getProjectSelects(userId) {
  return apiClient().get(constants.PROJECT_SELECTS + `?userId=${userId}`);
}

export function f_getCampusList(projectId) {
  return apiClient().get(constants.CAMPUS_LIST + `?projectId=${projectId}`);
}

export function f_getBuildList(campusId) {
  return apiClient().get(constants.BUILD_LIST + `?campusId=${campusId}`);
}

export function f_getFloorList(buildId) {
  return apiClient().get(constants.FLOOR_LIST + `?buildId=${buildId}`);
}

export function f_getRoomList(floorId) {
  return apiClient().get(constants.ROOM_LIST + `?floorId=${floorId}`);
}

export function f_getProjectById(projectId) {
  return apiClient().get(constants.GET_PROJECT + `?projectId=${projectId}`);
}

export function f_getCampusById(campusId) {
  return apiClient().get(constants.GET_CAMPUS + `?campusId=${campusId}`);
}

export function f_addCampus(projectID, name, address) {
  const jsonData = {
    projectID,
    name,
    address,
  };

  return apiClient().post(constants.CREATE_CAMPUS, jsonData);
}

export function f_addBuild(campusID, name) {
  const jsonData = {
    campusID,
    name,
  };

  return apiClient().post(constants.CREATE_BUILD, jsonData);
}

export function f_getBuildById(buildId) {
  return apiClient().get(constants.GET_BUILD + `?buildId=${buildId}`);
}

export function f_addFloor(buildID, name) {
  const jsonData = {
    buildID,
    name,
  };

  return apiClient().post(constants.CREATE_FLOOR, jsonData);
}

export function f_getFloorById(floorId) {
  return apiClient().get(constants.GET_FLOOR + `?floorId=${floorId}`);
}

export function f_addRoom(floorID, name) {
  const jsonData = {
    floorID,
    name,
  };

  return apiClient().post(constants.CREATE_ROOM, jsonData);
}

export function f_getGroupList(projectId) {
  return apiClient().get(constants.GET_GROUP + `?projectId=${projectId}`);
}

export function f_getBrandList(groupId) {
  return apiClient().get(constants.GET_BRAND + `?groupId=${groupId}`);
}

export function f_getModelList(brandId) {
  return apiClient().get(constants.GET_MODEL + `?brandId=${brandId}`);
}

export function f_addGroup(projectID, name) {
  return apiClient().post(constants.ADD_GROUP, { projectID, name });
}

export function f_addBrand(groupID, name) {
  const jsonData = {
    groupID,
    name,
  };
  return apiClient().post(constants.ADD_BRAND, jsonData);
}

export function f_addModel(brandID, name) {
  const jsonData = {
    brandID,
    name,
  };
  return apiClient().post(constants.ADD_MODEL, jsonData);
}

export function f_getCapacityList() {
  return apiClient().get(constants.CAPACITY_LIST);
}

export function f_addInventory(formData) {
  return apiClient().post(constants.ADD_INVENTORY, formData, {
    headers: { ...formDataOptions },
  });
}

export function f_getInventoryList(projectId) {
  return apiClient().get(constants.INVENTORY_LIST + `?projectId=${projectId}`);
}

export function f_updateInventory(jsonData) {
  return apiClient().post(constants.UPDATE_INVENTORY, jsonData);
}

export function f_addMaintenance(jsonData) {
  return apiClient().post(constants.ADD_INVENTORY, jsonData);
}

export function f_getInventoryById(inventoryId) {
  return apiClient().get(
    constants.GET_INVENTORY + `?inventoryId=${inventoryId}`
  );
}
export function f_getInventoryByBarcode(barcode) {
  return apiClient().get("/Inventory/GetInventoryByBarcode", {
    params: { barcode },
  });
}

export function f_getMqFormList(projectId) {
  return apiClient().get(constants.MQ_LIST + `?projectId=${projectId}`);
}

export function f_getOhsFormList(projectId) {
  return apiClient().get(constants.OHS_LIST + `?projectId=${projectId}`);
}

export function f_getIAFormList(companyId) {
  return apiClient().get(constants.IA_LIST + `?companyId=${companyId}`);
}

export function f_getMaintenanceTypeList(projectId) {
  return apiClient().get(
    constants.MAINTENANCETYPES_LIST + `?projectId=${projectId}`
  );
}

export function f_getMaintenancePeriodList(projectId) {
  return apiClient().get(
    constants.MAINTENANCEPERIOD_LIST + `?projectId=${projectId}`
  );
}

export function f_addMaintenanceToInventory(jsonData) {
  return apiClient().post(constants.ADD_MAINTENANCE, jsonData);
}

export function f_getMaintenanceList(inventoryId) {
  return apiClient().get(
    constants.INVENTORYMAINTENANCE_LIST + `?inventoryId=${inventoryId}`
  );
}

export function f_getMQuestionList(companyId) {
  return apiClient().get(constants.MQUESTION_LIST + `?companyId=${companyId}`);
}

export function f_addMQuest(question, isOptionQuestion, companyId) {
  const jsonData = {
    question,
    isOptionQuestion,
    companyId,
  };

  return apiClient().post(constants.ADD_MQUESTION, jsonData);
}

export function f_getMQForm(projectId) {
  return apiClient().get(constants.MQFORM_LIST + `?projectId=${projectId}`);
}

export function f_addMqForm(name, projectId) {
  const jsonData = {
    name,
    projectID: projectId,
  };
  return apiClient().post(constants.ADD_MQFORM, jsonData);
}

export function f_addQuestToForm(mqFormTypeID, mQuestionID) {
  const jsonData = {
    mqFormTypeID,
    mQuestionID,
  };

  return apiClient().post(constants.ADD_QUEST_FORM, jsonData);
}

export function f_deleteQuestToForm(mqFormTypeID, mQuestionID) {
  const jsonData = {
    mqFormTypeID,
    mQuestionID,
  };

  return apiClient().post(constants.DELETE_QUEST_FORM, jsonData);
}

export function f_getMQuestByFormId(formId) {
  return apiClient().get(constants.QUEST_LIST_BY_FORM + `?mqFormId=${formId}`);
}

export function f_getOHSQuestByFormId(formId) {
  return apiClient().get(constants.OHS_QUEST_FORM + `?ohsFormId=${formId}`);
}

export function f_addMQuestMultiForm(mqFormTypeID, mQuestionID) {
  const jsonData = {
    mqFormTypeID,
    mQuestionID,
  };
  return apiClient().post(constants.ADD_MQUEST_MULTI_FORM, jsonData);
}

export function f_addOHSQuestMultiForm(ohsFromTypeID, ohsQuestionID) {
  const jsonData = {
    ohsFromTypeID,
    ohsQuestionID,
  };
  return apiClient().post(constants.ADD_OHSQUEST_MULTI_FORM, jsonData);
}

export function f_deleteOHSQuestMultiForm(ohsFromTypeID, ohsQuestionID) {
  const jsonData = {
    ohsFromTypeID,
    ohsQuestionID,
  };
  return apiClient().post(constants.DELETE_OHSQUEST_FORM, jsonData);
}

export function f_addOhsQuest(questionText, notRequired, companyId) {
  const jsonData = {
    questionText,
    notRequired,
    companyId,
  };

  return apiClient().post(constants.ADD_OHSQUEST, jsonData);
}

export function f_getOhsQuestList(companyId) {
  return apiClient().get(
    constants.GET_OHSQUEST_LIST + `?companyId=${companyId}`
  );
}

export function f_addOhsForm(name, projectID) {
  const jsonData = {
    name,
    projectID,
  };

  return apiClient().post(constants.ADD_OHSFORM, jsonData);
}

export function f_getNoteList(projectId) {
  return apiClient().get(constants.NOTE_LIST + `?projectId=${projectId}`);
}

export function f_addNote(projectID, userID, title, body) {
  const jsonData = { projectID, userID, title, body };
  return apiClient().post(constants.ADD_NOTE, jsonData);
}

export function f_getPeriodList(projectId) {
  return apiClient().get(constants.PERIOD + `?projectId=${projectId}`);
}

export function f_addPeriod(projectID, name) {
  const jsonData = { projectID, name };
  return apiClient().post(constants.ADD_PERIOD, jsonData);
}

export function f_getMTypeList(projectId) {
  return apiClient().get(constants.MTYPE + `?projectId=${projectId}`);
}

export function f_addMType(projectID, name) {
  const jsonData = { projectID, name };
  return apiClient().post(constants.ADD_MTYPE, jsonData);
}

export function f_getUserList(projectId) {
  return apiClient().get(constants.USER_LIST + `?projectId=${projectId}`);
}

export function f_getUserRoles() {
  return apiClient().get(constants.USER_ROLE);
}

export function f_addUser(jsonData) {
  return apiClient().post(constants.ADD_USER, jsonData);
}

export function f_addCapacity(name) {
  return apiClient().post(constants.ADD_CAPACITY, { name });
}

export function f_getUserProjects(userId) {
  return apiClient().get(constants.USER_PROJECTS + `?userId=${userId}`);
}

export function f_addProjectUser(userID, projectID) {
  return apiClient().post(constants.ADD_PROJECT_USER, { userID, projectID });
}

export function f_getProjectList(companyId) {
  return apiClient().get(constants.PROJECT_LIST + `?companyId=${companyId}`);
}

export function f_getPeriodEnums() {
  return apiClient().get(constants.MAINTENANCEPERIOD_LIST_ENUM);
}

export function f_getTypeEnums() {
  return apiClient().get(constants.MAINTENANCETYPES_LIST_ENUM);
}

export function f_addIA(formData) {
  return apiClient().post(constants.ADD_IA, formData, {
    headers: { ...formDataOptions },
  });
}

export function f_editUser(jsonData) {
  return apiClient().post(
    constants.EDIT_USER + "?projectId=" + jsonData.projectID,
    jsonData
  );
}

export function f_timeline(projectId = 33) {
  return apiClient().get(constants.TIMELINE + `?ProjectID=${projectId}`);
}

export function f_maintenanceNotes(projectId) {
  return apiClient().get(
    constants.MAINTENANCE_NOTE_LIST + `?projectId=${projectId}`
  );
}

export function f_homepageStatistics(projectId, dates = null) {
  let queryParams = `?projectId=${projectId}`;
  if (dates) {
    queryParams += `&startDate=${dates.startDate}&endDate=${dates.endDate}`;
  }
  return apiClient().get(constants.HOMEPAGE_STATISTICS + queryParams);
}

export function f_upcomingMaintenance(projectId) {
  return apiClient().get(
    constants.UPCOMING_MAINTENANCE + `?projectId=${projectId}`
  );
}

export function f_completedMaintenance(projectId, dates = undefined) {
  return apiClient().get(
    constants.COMPLETED_MAINTENANCE +
      `?projectId=${projectId}${
        dates ? `&startDate=${dates.startDate}&endDate=${dates.endDate}` : ""
      }`
  );
}

export function f_undoneMaintenance(projectId, dates = undefined) {
  return apiClient().get(
    constants.UNDONE_MAINTENANCE +
      `?projectId=${projectId}${
        dates ? `&startDate=${dates.startDate}&endDate=${dates.endDate}` : ""
      }`
  );
}

export function f_abandonedLegalMaintenance(projectId) {
  return apiClient().get(
    constants.ABANDONED_LEGAL_MAINTENANCE + `?projectId=${projectId}`
  );
}

export function f_getInventoriesByMainType(projectId) {
  return apiClient().get(
    constants.GET_INVENTORY_MAINTENANCE_TYPE_LIST + `?projectId=${projectId}`
  );
}

export function f_changePassword(userID, oldPass, newPass) {
  return apiClient().get(constants.CHANGE_PASSWORD, {
    params: { userID, oldPass, newPass },
  });
}

export function f_inventoryToggle(inventoryID, isActive) {
  return apiClient().get("/Inventory/InventoryActiveStatusSet", {
    params: { inventoryID, isActive },
  });
}

export function f_getUserId(userId) {
  return apiClient().get("/Users/GetUserById", {
    params: { userId },
  });
}

export function f_toggleInventoryMaintenanceType(typeId, isActive) {
  return apiClient().get(
    "/InventoryMaintenanceType/ChangeInventoryMaintenanceTypeStatus",
    { params: { typeId, isActive } }
  );
}

export function f_updateCampusById(campus, name) {
  return apiClient().post("/Campus/UpdateCampus", {
    ...campus,
    name,
  });
}

export function f_updateBuildById(build, name) {
  return apiClient().post("/Build/UpdateBuild", {
    ...build,
    name,
  });
}
export function f_updateFloorById(item, name) {
  return apiClient().post("/Floor/UpdateFloor", {
    ...item,
    name,
  });
}

export function f_updateRoomById(item, name) {
  return apiClient().post("/Room/UpdateRoom", {
    ...item,
    name,
  });
}

export function f_getMalfunctionList(projectId, params) {
  return apiClient().get(
    constants.BASE_URL_APP + "/odata/InventoryMalfunctionOdata",
    {
      params: {
        projectId,
        ...params,
      },
    }
  );
}

export function f_getMaintenanceReports(projectId, params) {
  return apiClient().get(
    constants.BASE_URL_APP + "/odata/MaintenanceReportOdata",
    {
      params: {
        projectId,
        ...params,
      },
    }
  );
}
export function f_getMaintenanceReportDetail(maintenanceReportId) {
  return apiClient().get("/MaintenanceReport/GetMaintenanceReportDetail", {
    params: {
      maintenanceId: maintenanceReportId,
    },
  });
}

export function f_getInventoryMaintenanceDetail(maintenanceId) {
  return apiClient().get(
    "/InventoryMaintenanceDetail/GetInventoryMaintenanceDetail",
    {
      params: {
        maintenanceID: maintenanceId,
      },
    }
  );
}
export function f_getInventoryMaintenanceTypeList(inventoryId) {
  return apiClient().get(
    "/InventoryMaintenanceType/GetInventoryMaintenanteTypeList",
    {
      params: {
        inventoryId,
      },
    }
  );
}
