export function saveUser(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function getUser() {
  const userStr = localStorage.getItem("user");
  if(!!userStr) {
    return JSON.parse(userStr);
  }
  return null;
}

export function clearUser() {
  localStorage.removeItem("user");
}


export function savePdf(data) {
  localStorage.setItem("report", JSON.stringify(data));
}
export function getPdf() {
  const data = localStorage.getItem("report");
  if(!!data) {
    return JSON.parse(data);
  }
  return null;
}

export function clearPdf() {
  localStorage.removeItem("report");
}