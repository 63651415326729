import axios from "axios";
import { BASE_URL } from "./constants";
import { store } from "../store";

export const apiClient = () => {
  const reduxStore = store.getState();
  const worker = axios.create();
  worker.defaults.baseURL = BASE_URL;
  if (reduxStore.authStore.user) {
    worker.defaults.headers.common["Authorization"] =
      "Bearer " + reduxStore.authStore.user.accessToken;
  }
  worker.interceptors.response.use((response) => {return response}, (error) => {return Promise.reject(error)});
  return worker;
};

export const apiAuth = axios.create({
  baseURL: BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json"
  }
});
