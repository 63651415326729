import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {Provider} from "react-redux";
import { store } from "./store";



import "./assets/plugins/flaticon/flaticon.css";
import "./assets/plugins/flaticon2/flaticon2.css";
import "./assets/plugins/line-awesome/css/line-awesome.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/sass/style.scss";
import "./index.css";

// console.log = () => {};

const root = <Provider store={store}><App /></Provider>

ReactDOM.render(root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
