import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import Moment from "moment";
import { store } from "./store";
import configs from "./configs/configs.json";

// lazy load all the views
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const CampusList = lazy(() => import("./pages/campusList/CampusList"));
const BuildList = lazy(() => import("./pages/buildList/BuildList"));
const FloorList = lazy(() => import("./pages/floorList/FloorList"));
const RoomList = lazy(() => import("./pages/roomList/RoomList"));

const NotFound = lazy(() => import("./pages/errorPages/NotFound"));

// Auth Pages
const Login = lazy(() => import("./pages/auth/Login"));
const Logout = lazy(() => import("./pages/auth/Logout"));

const ProjectSelect = lazy(() => import("./pages/projectSelect/ProjectSelect"));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const reduxStore = store.getState();
      const loggedInUser = reduxStore.authStore.isLogged;
      if (!loggedInUser) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      const projectID = reduxStore.authStore.user.projectID;
      if (!projectID) {
        return (
          <Redirect
            to={{
              pathname: "/project-select",
              state: { from: props.location },
            }}
          />
        );
      }

      const { tokenExpireDate } = reduxStore.authStore.user;
      const exp = Moment(tokenExpireDate, configs.dateString);
      var tokenExpUtc = Moment(tokenExpireDate);
      var todayUtc = Moment().utc();
      var d = tokenExpUtc.diff(todayUtc, "minutes");
      if (d > 0) {
        //a is bigger than b actual moment.
      } else if (d < 0) {
        //a is smaller than b actual moment.
        return <Redirect to="/logout" />;
      } else {
        //a =  b
        return <Redirect to="/logout" />;
      }

      //  // check if route is restricted by role
      //   if (roles && roles.indexOf(loggedInUser.role) === -1) {
      //     // role not authorised so redirect to home page
      //     return <Redirect to={{ pathname: '/' }} />
      //   }
      const { userRoleID } = reduxStore.authStore.user;
      if (roles && roles.indexOf(userRoleID) === -1) {
        return <Redirect to={{ pathname: "/" }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

const routes = [
  // auth and account
  {
    path: "/",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/campus-list",
    exact: true,
    name: "Tesis Listesi",
    component: CampusList,
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/campus-list/:campus_id/builds",
    exact: true,
    name: "Bina Listesi",
    component: BuildList,
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/campus-list/:campus_id/builds/:build_id/floors",
    exact: true,
    name: "Kat Listesi",
    component: FloorList,
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/campus-list/:campus_id/builds/:build_id/floors/:floor_id/rooms",
    exact: true,
    name: "Oda Listesi",
    component: RoomList,
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/campus-create",
    exact: true,
    name: "Tesis Oluştur",
    component: lazy(() => import("./pages/campusList/CampusCreate")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/campus-list/build-create",
    exact: true,
    name: "Bina Oluştur",
    component: lazy(() => import("./pages/buildList/BuildCreate")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/campus-list/:campus_id/builds/:build_id/floor-create",
    exact: true,
    name: "Kat Oluştur",
    component: lazy(() => import("./pages/floorList/FloorCreate")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/room-create",
    exact: true,
    name: "Oda Oluştur",
    component: lazy(() => import("./pages/roomList/RoomCreate")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/inventory-create",
    exact: true,
    name: "Ekipman Tanımla",
    component: lazy(() =>
      import("./pages/envanterPages/envanterCreate/EnvanterCreate")
    ),
    route: PrivateRoute,
    layout: "main",
    roles: [1, 3],
  },
  {
    path: "/inventory-list",
    exact: true,
    name: "Ekipman Listesi",
    component: lazy(() =>
      import("./pages/envanterPages/envanterList/EnvanterList")
    ),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/inventory-edit/:id",
    exact: true,
    name: "Ekipman Düzenle",
    component: lazy(() => import("./pages/envanterPages/envanterEdit")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/inventory-list/:id/malfunction-list",
    exact: true,
    name: "Arıza Listesi",
    component: lazy(() => import("./pages/malfunctionList/MalfunctionList")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/maintenance-create/:inventory_id",
    exact: true,
    name: "Bakım Oluştur",
    component: lazy(() =>
      import("./pages/maintenancePages/maintenanceCreate/MaintenanceCreate")
    ),
    route: PrivateRoute,
    layout: "main",
    roles: [1, 3],
  },
  {
    path: "/inventory-list/:inventory_id/maintenance-list/",
    exact: true,
    name: "Bakım Listesi",
    component: lazy(() =>
      import("./pages/maintenancePages/maintenanceList/MaintenanceList")
    ),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/maintenance-inventories/",
    exact: true,
    name: "Kayıtlı Bakımlar",
    component: lazy(() => import("./pages/inventoryList")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/maintenance-questions",
    exact: true,
    name: "Bakım Soruları",
    component: lazy(() =>
      import("./pages/maintenanceForm/MaintenanceQuestions")
    ),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/maintenance-form-list",
    exact: true,
    name: "Bakım Soru Grubu",
    component: lazy(() => import("./pages/maintenanceForm/MQForms")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/maintenance-form-list/:form_id/questions",
    exact: true,
    name: "Bakım Soruları",
    component: lazy(() => import("./pages/maintenanceForm/MQFormQuest")),
    route: PrivateRoute,
    layout: "main",
    roles: [1, 2, 3],
  },
  {
    path: "/maintenance-notes",
    exact: true,
    name: "Bakım Tespitleri",
    component: lazy(() => import("./pages/maintenanceNote")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/ohs-questions",
    exact: true,
    name: "ISG Soruları",
    component: lazy(() => import("./pages/ohsForm/OHSQuests")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/ohs-form-list",
    exact: true,
    name: "ISG Soru Grubu",
    component: lazy(() => import("./pages/ohsForm/OHSForm")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/ohs-form-list/:form_id/questions",
    exact: true,
    name: "ISG Soruları",
    component: lazy(() => import("./pages/ohsForm/OHSFormQuest")),
    route: PrivateRoute,
    layout: "main",
    roles: [1, 2, 3],
  },
  {
    path: "/notes",
    exact: true,
    name: "Duyurular",
    component: lazy(() => import("./pages/note/Note")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/capacities",
    exact: true,
    name: "Kapasite",
    component: lazy(() => import("./pages/inventoryAgreement/Capacity")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/inventory-agreements",
    exact: true,
    name: "İSG Risk Analizi",
    component: lazy(() => import("./pages/inventoryAgreement/IAList")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/general-reports",
    exact: true,
    name: "Genel Bakım Takip",
    component: lazy(() => import("./pages/reports/generalMaintenanceFollow")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/general-reports/coming-maintenance",
    exact: true,
    name: "Yaklaşan Bakımlar",
    component: lazy(() => import("./pages/reports/comingMaintenance")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/general-reports/maintenance-report-list",
    exact: true,
    name: "Ekipman Bakım Raporları",
    component: lazy(() => import("./pages/reports/maintenanceOdata/MaintenanceOData")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/general-reports/completed-w-uncompleted-maintenance",
    exact: true,
    name: "Gerçekleşen Ve Gerçekleşmeyen Bakımlar",
    component: lazy(() => import("./pages/reports/completedWUnCompletedMaintenance")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/general-reports/completed-maintenance",
    exact: true,
    name: "Gerçekleşen Bakımlar",
    component: lazy(() => import("./pages/reports/completedMaintenance")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/general-reports/undone-maintenance",
    exact: true,
    name: "Gerçekleşmeyen Bakımlar",
    component: lazy(() => import("./pages/reports/undoneMaintenance")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/abandoned-legal-maintenance",
    exact: true,
    name: "Yasal Muayene Yarıda Kaldı",
    component: lazy(() => import("./pages/reports/legalMaintenance")),
    route: PrivateRoute,
    layout: "main",
  },
  // {
  //   path: "/maintenance-form-list/periods",
  //   exact: true,
  //   name: "Bakım Formu Periyotları",
  //   component: lazy(() => import("./pages/maintenanceCommon/Period")),
  //   route: PrivateRoute,
  //   layout: "main"
  // },
  // {
  //   path: "/maintenance-form-list/types",
  //   exact: true,
  //   name: "Bakım Formu Tipleri",
  //   component: lazy(() => import("./pages/maintenanceCommon/Types")),
  //   route: PrivateRoute,
  //   layout: "main"
  // },
  {
    path: "/user-list",
    exact: true,
    name: "Kullanıcılar",
    component: lazy(() => import("./pages/users/UserList")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/user-list/:user_id/projects",
    exact: true,
    name: "Kullanıcı Projeleri",
    component: lazy(() => import("./pages/users/UserProjects")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: Login,
    route: Route,
    layout: "auth",
  },
  {
    path: "/logout",
    exact: true,
    name: "Logout",
    component: Logout,
    route: Route,
    layout: "free",
  },
  {
    path: "/project-select",
    exact: true,
    name: "Proje Seçim",
    component: ProjectSelect,
    route: Route,
    layout: "free",
  },
  {
    path: "/report-form",
    exact: true,
    name: "Form",
    component: lazy(() => import("./pages/reports/result")),
    route: Route,
    layout: "free",
  },
  {
    path: "/change-password",
    exact: true,
    name: "Parola Değiştir",
    component: lazy(() => import("./pages/changePassword")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/change-infos",
    exact: true,
    name: "Bilgileri Değiştir",
    component: lazy(() => import("./pages/changeInfos")),
    route: PrivateRoute,
    layout: "main",
  },
  {
    path: "/show-report",
    exact: true,
    name: "Raporu Görüntüle",
    component: lazy(() => import("./pages/reports/showReport")),
    route: PrivateRoute,
    layout: "free",
  },

  // 404 Route
  { path: "", name: "", component: NotFound, route: Route, layout: "free" },
];

export { routes };
